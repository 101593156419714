import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
`;

const FaqData = [
  {
    question: 'Do you support woocommerce, shopify?',
    content: (
      <>
        Yes you are able to integrate using our GoGet API documentation, however
        we do not have API plug ins.
        <br />
        <br />
      </>
    )
  },
  {
    question: 'What are your rates?',
    content: (
      <>
        Our deliveries start from RM5 for the first 5km.
        <br />
        <br />
        These are the rates GoGet charges you. You can choose to charge your
        customers the same rates or a rate which you deem appropriate for your
        business model.
        <br />
        <br />
        <Wrapper style={{}}>
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/900000500263-What-are-our-rates-">
            See all rates
          </a>
        </Wrapper>
      </>
    )
  },
  {
    question: 'What documentation do I need to get started?',
    content: (
      <>
        No documents needed! Simply sign up for a GoGet Business account to get
        started. There are absolutely no costs to integrate.
        <br />
        <br />
        <Wrapper style={{}}>
          <a href="https://app.goget.my/credits?business=true">
            Click here to sign up
          </a>
        </Wrapper>
      </>
    )
  },
  {
    question: 'How long will it take to integrate? ',
    content: (
      <>
        Integration would largely depend on how fast your developers integrate
        with GoGet. Relevant support and channels for communication will be
        opened with the GoGet team to ensure seamless communication. We have
        seen most of our partners integrate within 1-2 weeks. This depends on
        the timeline of your developers who are integrating to GoGet.
        <br />
        <br />
        There are absolutely no costs to integrate all you need to do is sign up
        for a business account.
        <br />
        <br />
      </>
    )
  },
  {
    question: 'What is your coverage area?',
    content: (
      <>
        GoGet covers Klang Valley, Penang (Mainland + Island) and Johor Bahru.
        <br />
        <br />
        <Wrapper style={{}}>
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/115006801327-What-is-your-coverage-area-">
            See all postcodes
          </a>
        </Wrapper>
      </>
    )
  },
  {
    question: 'Can I cancel or edit  an API job? ',
    content: (
      <>
        You can cancel or edit an API job before a GoGetter is matched at no
        cost.
        <br />
        <br />
        However, once a GoGetter has been matched, cancellation penalty charges
        would apply.
        <br />
        <br />
        <Wrapper style={{}}>
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/10439959516313">
            Read about terms and conditions
          </a>
        </Wrapper>
      </>
    )
  },
  {
    question: 'How do I check on my job status? ',
    content: (
      <>
        We send job status updates at various points of the delivery journey.
        <br />
        <br />
        These points include:
        <br />
        1. When a GoGetter has been matched.
        <br />
        2. The GoGetter is on the way to the designated pick up location.
        <br />
        3. The GoGetter has picked up the item.
        <br />
        4. Lastly when the item has been delivered safely.
        <br />
        <br />
      </>
    )
  },
  {
    question: 'Do you inform my customer when the delivery is on the way? ',
    content: (
      <>
        Yes we do!
        <br />
        <br />
        You can either show the customer our unique tracking url link that is
        generated or your customers can receive an SMS with a tracking link.
        <br />
        <br />
      </>
    )
  }
];

export default FaqData;
