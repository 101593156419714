import React from 'react';
import styled from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';

import TitleHeaderText from '../../components/TitleHeaderText';
import Button from '../../components/Button';

import colors from '../../assets/colors';

const ExclusivePromotionImageMobile =
  './const/assets/ExclusivePromotionImageMobile.png';
const ExclusivePromotionImageDesktop =
  './const/assets/ExclusivePromotionImageDesktop.png';

const Container = styled.div`
  max-width: 1050px;
  margin: 0 auto;
  width: 90%;
  position: relative;
  margin-top: 6rem;
  margin-bottom: 6rem;
`;
const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  @media only screen and (max-width: 760px) {
    display: block;
  }
`;
const LeftSide = styled.div`
  max-width: 465px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 760px) {
    max-width: unset;
  }
  a {
    text-decoration: none;
  }
`;
const RightSide = styled.div`
  width: 535px;
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: 760px) {
    width: 90%;
    margin: 0 auto;
  }
  @media only screen and (max-width: 550px) {
    width: 100%;
    margin: 0 auto;
  }
`;
const ImageDesktop = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  @media only screen and (max-width: 760px) {
    display: none;
  }
`;
const ImageMobile = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  @media only screen and (max-width: 760px) {
    display: block;
  }
`;
const DescriptionDesktop = styled.p`
  font-size: 19.2px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  margin-top: 0;
  margin-bottom: 3rem;
  @media only screen and (max-width: 760px) {
    display: none;
  }
`;
const DescriptionMoible = styled(DescriptionDesktop)`
  display: none;
  br {
    display: none;
  }
  @media only screen and (max-width: 760px) {
    display: block;
  }
  @media only screen and (max-width: 460px) {
    max-width: 300px;
  }
`;
const StyledButton = styled(Button)`
  border: 2px solid ${colors.primary} !important;
  height: 85px !important;
  width: 217px !important;
  @media only screen and (max-width: 760px) {
    display: none !important;
  }
`;
const MobileStyledButton = styled(StyledButton)`
  margin: 3rem auto 0 !important;
  font-weight: bold !important;
  display: none !important;
  @media only screen and (max-width: 760px) {
    display: block !important;
  }
`;
const ButtonWrapper = ({ children }) => (
  <a href="https://app.goget.my/partners">{children}</a>
);

const BecomeExecutivePartner = () => {
  const description = (
    <>
      Have your brand featured when you integrate <br /> with us and gain more
      awareness.
    </>
  );
  const buttonText = <>VIEW ALL EXCLUSIVE PROMOTIONS</>;
  return (
    <Container>
      <InnerWrapper>
        <LeftSide>
          <TitleHeaderText noMargin text="Become an exclusive partner" />
          <DescriptionDesktop>{description}</DescriptionDesktop>
          <DescriptionMoible>{description}</DescriptionMoible>
          <ButtonWrapper>
            <StyledButton primary noshadow="true" variant="outlined">
              {buttonText}
            </StyledButton>
          </ButtonWrapper>
        </LeftSide>
        <RightSide>
          <ImageDesktop>
            <StaticImage
              src={ExclusivePromotionImageDesktop}
              placeholder="blurred"
              alt="Exclusive Partners"
            />
          </ImageDesktop>
          <ImageMobile>
            <StaticImage
              src={ExclusivePromotionImageMobile}
              placeholder="blurred"
              alt="Exclusive Partners"
            />
          </ImageMobile>
          <ButtonWrapper>
            <MobileStyledButton>{buttonText}</MobileStyledButton>
          </ButtonWrapper>
        </RightSide>
      </InnerWrapper>
    </Container>
  );
};
export default BecomeExecutivePartner;
